// temporary
import React from "react"
import loadable from "@loadable/component"
import { v4 as uuid } from "uuid"
import LazyHydrate from "react-lazy-hydration"
import { Link } from "gatsby"
import HomepageLayout from "components/layouts/HomepageLayout"
import ScrollLoadLayout from "components/layouts/ScrollLoadLayout"
import Container from "components/elements/Container"
import AboutContent from "components/layouts/AboutContent"
import PaybrightHeader from "components/elements/PaybrightHeader"
import PageHeading from "components/elements/StrapiPageContent/PageHeading"
import PageContent from "components/elements/StrapiPageContent/PageContent"
import PageHeader from "components/elements/PageHeader"

export function findContentComponent(name, data, collections) {
  let products = []

  switch (name) {
    case "products.product-listing":
      const ProductListing = loadable(() =>
        import("components/layouts/ProductListing")
      )

      const targetCollection =
        collections.length > 0
          ? collections.find(
              (collection) =>
                collection.shopifyId === data.collection.shopifyData.value
            )
          : null

      // Shopify will not return collections with no products
      products = targetCollection ? targetCollection : { products: [] }

    case "about.about-header":
      const AboutUsHeader = loadable(() =>
        import("components/elements/AboutUsHeader")
      )

      return (
        <Container key={uuid()}>
          <AboutUsHeader {...data} />
        </Container>
      )

    case "about.content-row":
      return (
        <Container key={uuid()}>
          <AboutContent {...data} />
        </Container>
      )

    case "about.mission":
      const MissionLayout = loadable(() => import("components/layouts/Mission"))

      return (
        <Container key={uuid()}>
          <MissionLayout {...data} />
        </Container>
      )

    case "about.team":
      const PersonGrid = loadable(() => import("components/layouts/PersonGrid"))

      return (
        <div key={uuid()}>
          <LazyHydrate noWrapper whenVisible>
            <PersonGrid {...data} />
          </LazyHydrate>
        </div>
      )

    case "subscription.coffee":
      const CoffeeSubscription = loadable(() =>
        import("components/elements/CTA")
      )

      return (
        <HomepageLayout layout="full">
          <LazyHydrate noWrapper whenVisible>
            <CoffeeSubscription {...data} />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "subscription.tiers":
      const TierSelection = loadable(() =>
        import("components/layouts/TierSelection")
      )

      return (
        <div key={uuid()}>
          <TierSelection {...data} />
        </div>
      )
    case "subscription.process":
      const StepsGrid = loadable(() => import("components/layouts/StepsGrid"))

      return (
        <div key={uuid()}>
          <StepsGrid {...data} />
        </div>
      )
    case "subscription.faqs":
      const SubscriptionFaq = loadable(() =>
        import("components/layouts/SubscriptionFaq")
      )

      return (
        <div key={uuid()}>
          <SubscriptionFaq {...data} />
        </div>
      )

    case "subscription.reviews":
      const BuildABoxReviews = loadable(() =>
        import("components/layouts/BuildABoxReviews")
      )

      return (
        <div key={uuid()}>
          <BuildABoxReviews {...data} />
        </div>
      )

    case "brands.grid":
      const BrandGrid = loadable(() => import("components/layouts/BrandGrid"))

      const brands = data.brands.map((brand) => {
        return {
          title: brand.title,
          image: brand.image.url,
          handle: brand?.collection?.shopifyData?.handle,
        }
      })
      return (
        <LazyHydrate noWrapper whenVisible>
          <BrandGrid title={data.title} brands={brands} />
          <div className="hidden float-right flex-col justify-end md:flex">
            <Link
              className="flex flex-row text-lg font-bold text-idc-title hover:text-idc-orange"
              to="/brands"
            >
              Show all brands
              <div className="flex flex-col justify-center ml-2">
                <svg
                  width="15"
                  height="15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.673 1.698 13.475 7.5l-5.802 5.802M13.185 7.5H1"
                    stroke="#EF4223"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
            </Link>
          </div>
        </LazyHydrate>
      )

    case "categories.grid":
      const CategoryGrid = loadable(() =>
        import("components/layouts/CategoryGrid")
      )

      const categories = data.categories.map((category) => {
        return {
          title: category.title,
          image: category.image,
          handle: category?.collection?.shopifyData?.handle,
          onSale: Boolean(category?.onSale),
        }
      })

      return (
        <HomepageLayout lazy={true}>
          <LazyHydrate noWrapper whenVisible>
            <CategoryGrid title={data.title} categories={categories} />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "page-content.heading":
      return <PageHeading text={data.text} />

    case "page-content.image":
      const PageHeadingImage = loadable(() =>
        import("components/elements/StrapiPageContent/PageHeadingImage")
      )

      return (
        <HomepageLayout>
          <LazyHydrate noWrapper whenVisible>
            <PageHeadingImage imageUrl={data?.image} />
          </LazyHydrate>
        </HomepageLayout>
      )
    case "page-content.content":
      return (
        <HomepageLayout>
          <LazyHydrate noWrapper whenVisible>
            <PageContent content={data.content} />
          </LazyHydrate>
        </HomepageLayout>
      )

    case "page-content.paybright-header":
      return (
        <Container key={uuid()}>
          <div className="mt-6 lg:mt-20">
            <PaybrightHeader {...data} />
          </div>
        </Container>
      )

    case "contact.contact-header":
      return (
        <Container key={uuid()}>
          <div className="mt-6 lg:mt-20">
            <PageHeader {...data} />
          </div>
        </Container>
      )

    case "contact.email-addresses":
      const ContactCard = loadable(() =>
        import("components/elements/ContactCard")
      )

      return (
        <Container key={uuid()}>
          <ContactCard {...data} />
        </Container>
      )

    case "contact.location":
      const LocationCard = loadable(() =>
        import("components/elements/LocationCard")
      )

      return (
        <Container key={uuid()}>
          <LocationCard {...data} />
        </Container>
      )

    case "contact.directions":
      return (
        <Container key={uuid()}>
          <AboutContent {...data} singleImage={true} restrictWidth={true} />
        </Container>
      )

    case "newsletter.sign-up-form":
      const SignUpForm = loadable(() =>
        import("components/elements/SignUpForm")
      )

      return (
        <Container key={uuid()}>
          <SignUpForm {...data} />
        </Container>
      )

    case "embed.econolease-form":
      const CommercialLeaseCalculator = loadable(() =>
        import("components/elements/CommercialLeaseCalculator")
      )

      return (
        <ScrollLoadLayout>
          <Container key={uuid()}>
            <CommercialLeaseCalculator {...data} />
          </Container>
        </ScrollLoadLayout>
      )

    case "embed.iframe":
      const EmbeddedIframe = loadable(() =>
        import("components/elements/Embeds/Iframe")
      )

      return (
        <ScrollLoadLayout>
          <Container key={uuid()}>
            <EmbeddedIframe {...data} />
          </Container>
        </ScrollLoadLayout>
      )

    case "embed.script-block":
      const EmbeddedScript = loadable(() =>
        import("components/elements/Embeds/ScriptBlock")
      )

      return (
        <ScrollLoadLayout>
          <div key={uuid()}>
            <EmbeddedScript {...data} />
          </div>
        </ScrollLoadLayout>
      )

    case "embed.yotpo-module":
      const YotpoModule = loadable(() =>
        import("components/elements/Embeds/YotpoModule")
      )

      return (
        <ScrollLoadLayout>
          <div key={uuid()}>
            <YotpoModule {...data} />
          </div>
        </ScrollLoadLayout>
      )

    case "brands.logo-cloud":
      const LogoCloud = loadable(() => import("components/elements/LogoCloud"))

      return (
        <div key={uuid()}>
          <LogoCloud {...data} />
        </div>
      )

    case "commercial.commercial-services":
      const CommercialServives = loadable(() =>
        import("components/layouts/CommercialServices")
      )

      return (
        <div key={uuid()}>
          <LazyHydrate noWrapper whenVisible>
            <CommercialServives {...data} />
          </LazyHydrate>
        </div>
      )

    case "commercial.commercial-partners":
      const CommercialPartners = loadable(() =>
        import("components/layouts/CommercialPartners")
      )

      return (
        <div key={uuid()}>
          <LazyHydrate noWrapper whenVisible>
            <CommercialPartners {...data} />
          </LazyHydrate>
        </div>
      )

    case "commercial.commercial-header":
      const CommercialHeader = loadable(() =>
        import("components/layouts/CommercialHeader")
      )

      return (
        <div key={uuid()}>
          <LazyHydrate noWrapper whenVisible>
            <CommercialHeader {...data} />
          </LazyHydrate>
        </div>
      )

    default:
  }
}

// function findComponentLayout(name){

// }
