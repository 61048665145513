import React from "react"
import { graphql } from "gatsby"
import { Layout } from "components/layouts/Layout/layout"
import { findContentComponent } from "utils/findContentComponent"
import { Seo } from "components/seo"

const cmsPageContent = ({ data, pageContext }) => {
  const pageData = pageContext.main
  const { title, description, image } = pageContext
  const collections = data.collections.edges.map((edge) => {
    return {
      products: edge.node.products,
      shopifyId: edge.node.shopifyId,
    }
  })

  return (
    <Layout>
      {image?.localFile?.url ? (
        <Seo
          title={title}
          description={description}
          image={image.localFile.url}
        />
      ) : (
        <Seo title={title} description={description} />
      )}
      {pageData.map((item, index) => {
        return (
          <React.Fragment key={`${item.strapi_component}-${index}`}>
            {findContentComponent(item.strapi_component, item, collections)}
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query PageContentQuery($shopifyIds: [String!]!) {
    collections: allShopifyCollection(
      filter: { shopifyId: { in: $shopifyIds } }
    ) {
      edges {
        node {
          id
          handle
          description
          shopifyId
          legacyResourceId
          image {
            originalSrc
            transformedSrc
          }
          title
          products(limit: 12) {
            handle
            title
            legacyResourceId
            images {
              id
              altText
              gatsbyImageData(layout: CONSTRAINED, width: 320)
            }
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            productType
            variants {
              compareAtPrice
              price
            }
            tags
          }
        }
      }
    }
  }
`

export default cmsPageContent
