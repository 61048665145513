import React from "react"
import { getResourceUrl } from "utils/strapi"

const PaybrightHeader = ({ title, logo, logoAltText, features = [] }) => {
  const colorOptions: any = {
    pink: "idc-light-red",
    orange: "idc-light-yellow",
    gray: "idc-light-blue",
    green: "idc-light-green",
    transparent: "",
  }

  return (
    <div className="mb-24">
      <h1 className="text-center font-extrabold text-5xl text-idc-title">
        {title} {logoAltText && <span className="sr-only">{logoAltText}</span>}
      </h1>
      {logo && (
        <div className="mx-auto mt-4 h-[3.125rem] relative">
          <img
            className="absolute w-full h-full object-contain"
            src={getResourceUrl(logo.url)}
            alt={logoAltText}
          />
        </div>
      )}

      {features.length > 0 ? (
        <div className="mt-10 grid sm:grid-cols-2 gap-y-6 sm:gap-x-6 lg:gap-x-8">
          {features.map((feature) => {
            return (
              <div key={feature.title} className="text-center">
                <div
                  className={`rounded-full w-36 h-36 p-8 mb-6 mx-auto bg-${
                    colorOptions[feature.background_color]
                  }`}
                >
                  <img
                    src={getResourceUrl(feature.icon.url)}
                    alt={feature.icon.alternativeText}
                  />
                </div>
                <h3 className="font-semibold text-title mb-1">
                  {feature.title}
                </h3>
                <p className="text-idc-body">{feature.subtitle}</p>
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default PaybrightHeader
