import React, { FC } from "react"
import StrapiImage from "components/elements/StrapiImage"

export interface PageHeaderProps {
  subtitle?: string
  title?: string
  description?: string
  image: {
    url: string
    alternativeText: string
  }
  height?: number
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  subtitle,
  description,
  image,
  height = 308,
  ...props
}) => {
  return (
    <div
      className="flex flex-col md:flex-row md:items-end mb-12 md:mb-20 lg:mb-32 md:h-[19.25rem]"
      {...props}
    >
      {title ? (
        <div className="md:max-w-[32.5rem] md:pr-[5.5rem] mb-4 md:mb-0">
          <h1 className="font-bold text-sm uppercase text-idc-brown mb-2">
            {title}
          </h1>
          <p className="font-extrabold text-idc-title text-2xl md:text-5xl m-0 mb-2 md:mb-4">
            {subtitle}
          </p>
          <p className="text-idc-body">{description}</p>
        </div>
      ) : null}
      <div className={`h-full ${title ? "md:w-full" : ""}`}>
        <StrapiImage
          image={image}
          className="object-cover w-full h-full rounded-xl"
          loading="lazy"
        />
      </div>
    </div>
  )
}

export default PageHeader
