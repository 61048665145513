import Markdown from "components/elements/Markdown"
import React from "react"

const PageContent = ({ content }: { content: string }) => {
  return (
    <div className="flex flex-row justify-center">
      <div className="max-w-4xl text-[1.0625rem] leading-[1.75rem] w-full px-6">
        <Markdown content={content} />
      </div>
    </div>
  )
}

export default PageContent
