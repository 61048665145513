import React, { FC } from "react"

interface IPageHeading {
  text: string
}

const PageHeading: FC<IPageHeading> = ({ text }) => {
  return (
    <div className="flex flex-row justify-center">
      <div className="max-w-4xl w-full px-6 my-10">
        <h1 className="text-center font-extrabold text-5xl break-all md:break-normal text-idc-title">
          {text}
        </h1>
      </div>
    </div>
  )
}

export default PageHeading
