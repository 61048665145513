import React, { useRef } from "react"
import { v4 as uuid } from "uuid"
import { useHasBeenVisible } from "hooks/useVisibility"

const HomepageLayout = ({ layout, children, lazy }: { children: any }) => {
  const section = useRef()
  const preload = useRef()
  const hasScrolled = useHasBeenVisible(section)
  const isScrolling = useHasBeenVisible(preload)

  if (lazy) {
    return (
      <>
        {hasScrolled || isScrolling ? (
          <div
            key={uuid()}
            className={`homesection flex flex-row justify-center ${
              layout === "full" ? "" : "mx-4 md:mx-6 lg:mx-10"
            }`}
          >
            <div
              className={`${layout === "full" ? "w-full" : "w-full max-w-7xl"}`}
            >
              {children}
            </div>
          </div>
        ) : (
          <div ref={section} className="homesection block h-[50vh]"></div>
        )}
      </>
    )
  } else {
    return (
      <div
        key={uuid()}
        className={`homesection flex flex-row justify-center ${
          layout === "full" ? "" : "mx-4 md:mx-6 lg:mx-10"
        }`}
      >
        <div className={`${layout === "full" ? "w-full" : "w-full max-w-7xl"}`}>
          {children}
        </div>
      </div>
    )
  }
}

export default HomepageLayout
