import React, { FC } from "react"
import StrapiImage from "components/elements/StrapiImage"

export interface SingleImageProps {
  image: {
    url: string
    alternativeText: string
  }
  backgroundColor: string
}

const SingleImage: FC<SingleImageProps> = ({
  image,
  backgroundColor,
  ...props
}) => {
  return (
    <div className="double-image-container" {...props}>
      <div
        className={`double-image-content rounded-full bg-${backgroundColor} py-12`}
      >
        <StrapiImage
          image={image}
          className="m-0 rounded-2xl object-cover h-full w-full"
          loading="lazy"
        />
      </div>
    </div>
  )
}

export default SingleImage
