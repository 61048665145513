import React, { FC } from "react"
import { getResourceUrl } from "utils/strapi"

export interface MultiImageProps {
  images: Array<any>
  reverse: boolean
  backgroundColor: string
}

const MultiImage: FC<MultiImageProps> = ({ images, backgroundColor }) => {
  return (
    <div className="double-image-container">
      <div
        className={`double-image-content rounded-full bg-${backgroundColor} flex flex-row justify-between gap-4 py-12 px-2`}
      >
        {images.map((image, index) => (
          <div
            key={image.file.url}
            className={`flex flex-col ${
              index === 0 ? "justify-end" : "justify-start"
            }`}
          >
            <img
              className="m-0 rounded-2xl"
              alt={image.alt_text}
              src={getResourceUrl(image.file.url)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultiImage
