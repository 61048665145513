import React, { FC } from "react"
import MultiImage from "components/elements/MultiImage/index"
import SingleImage from "components/elements/SingleImage"

export interface AboutContentProps {
  subtitle?: string
  title: string
  description: string
  reverse: boolean
  images?: any[]
  image?: {
    url: string
    alternativeText: string
  }
  background_color: string
  singleImage?: boolean
  restrictWidth?: boolean
}

const AboutContent: FC<AboutContentProps> = ({
  subtitle = "",
  title,
  description,
  reverse,
  images = [],
  image = { url: "", alternativeText: "" },
  background_color,
  singleImage = false,
  restrictWidth = false,
}) => {
  const colorOptions: any = {
    pink: "idc-light-red",
    yellow: "idc-light-yellow",
    gray: "idc-light-blue",
    green: "idc-light-green",
  }
  const widthStyle = restrictWidth ? "max-w-screen-lg mx-auto" : ""

  return (
    <div
      className={`flex justify-between flex-col mb-16  gap-y-6 ${widthStyle} ${
        reverse ? "md:flex-row-reverse" : "md:flex-row"
      }`}
    >
      <div
        className={`flex flex-1 ${reverse ? "justify-end" : "justify-start"}`}
      >
        {singleImage ? (
          <SingleImage
            image={image}
            backgroundColor={colorOptions[background_color]}
          />
        ) : (
          <MultiImage
            images={images}
            reverse={reverse}
            backgroundColor={colorOptions[background_color]}
          />
        )}
      </div>
      <div
        className={`flex flex-col justify-center ${
          reverse ? "md:mr-20" : "md:ml-20"
        }`}
      >
        <div className="max-w-[27.9475rem]">
          {subtitle !== "" && (
            <p className="font-bold text-sm text-idc-brown uppercase mb-2">
              {subtitle}
            </p>
          )}
          <h1 className="m-0 font-bold text-[2rem] leading-[2.375rem] max-w-[25rem] text-idc-title mb-4">
            {title}
          </h1>
          <p className="text-[1.0625rem] leading-7 text-idc-body">
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutContent
